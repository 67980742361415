<template>
    <div class="mb-3">
        <div class="row form-rounded-inputs align-items-end">
            <div class="col-12 col-xl-12">
                <!-- <div class="row align-items-center justify-content-between mb-2 mb-md-4">
                    <div class="col">
                        <h5 class="m-0">
                            <span class="f-w-700 f-20" v-text="$t('general.products')"></span>
                        </h5>
                    </div>
                    <div class="col-4 col-lg-3 col-xl-2" v-if="newCart.items.length">
                        <button class="btn btn-success rounded-pill w-100" @click="showProductModal = !showProductModal"
                            v-text="$t('general.button.add')"></button>
                    </div>
                </div> -->
                <table class="table table-sm table-striped ecart-table mb-0">
                    <thead>
                        <tr>
                            <th>
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h5 class="m-0">
                                            <span class="f-w-700 f-20" v-text="$t('general.products')"></span>
                                        </h5>
                                    </div>
                                    <div class="col-4 col-lg-3 col-xl-2" v-if="newCart.items.length">
                                        <button class="btn btn-success rounded-pill w-100"
                                            @click="showProductModal = !showProductModal"
                                            v-text="$t('general.button.add')"></button>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div class="produsct-list-order animated fadeIn mb-4 ">
                    <table class="table table-sm table-striped ecart-table mb-0" v-if="newCart.items.length">
                        <tbody class="">
                            <tr v-for=" (item,index) in newCart.items" :key="item.id">
                                <td class="">
                                    <div class="row">
                                        <div class="col-2 col-lg-1">
                                            <img v-image="item.image_url" class="img-contain-40 border bg-white">
                                        </div>
                                        <div class="col-10 col-lg-8">
                                            <p>
                                                <span v-text="item.name"> </span>
                                                <span v-text="item.variant_name"> </span>
                                                <br>
                                                <strong class="f-w-500"
                                                    v-text=" $t('general.form.price') + ': ' "></strong>
                                                <span class="text-success f-w-400"
                                                    v-text="fnFormatPrice(item.price , item.currency )"></span>
                                                <small class="text-warning f-w-400 ml-1" v-if="item.discount > 0"
                                                    v-text="fnFormatPrice(item.discount, item.currency) + ' OFF'  "></small>
                                            </p>
                                        </div>
                                        <div class="col-6 col-lg-2 ml-auto">
                                            <div class="form-group m-0">
                                                <input type="number" class="form-control form-control-sm"
                                                    v-model="item.quantity" :disabled="item.loading.updating"
                                                    @change="fnApiUpdateItem(index)">
                                            </div>
                                        </div>
                                        <div class="col-auto col-lg-auto">
                                            <button class="btn btn-light circle-30" @click="fnApiDeleteItem(index)"
                                                :disabled="item.loading.deleting">
                                                <i class="fa fa-spinner fa-spin fa-sm "
                                                    v-if=" item.loading.deleting"></i>
                                                <i class="fa fa-trash fa-sm" v-else></i>
                                            </button>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class=" h-100 animated fadeIn bg-light p-5 d-flex flex-column align-items-center jutify-content-center"
                        v-else>
                        <div class="my-auto">
                            <p class="text-center mb-2">
                                <strong class="text-muted" v-text="$t('messages.addProducts')"></strong>
                            </p>
                            <p class="text-center">
                                <button class="btn btn-success rounded-pill"
                                    @click="showProductModal = !showProductModal"
                                    v-text="$t('general.button.add')"></button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <pre>{{ newCart.items }}</pre> -->
        </div>

        <div class="row justify-content-between ">
            <div class="col-12 col-lg-6 col-xl-8">
                <div class="card shadow-none " v-if=" show.billingAddress ">
                    <div class="card-body ">
                        <div class="row justify-content-between align-items-center mb-1">
                            <div class="col-8">
                                <p class="f-w-500 m-0 text-info">
                                    <i class="fa fa-file-invoice  mr-2 "></i>
                                    <span v-text="$t('general.billingAddress')"></span>
                                </p>
                            </div>
                            <div class="col-auto d-flex">
                                <button class="btn btn-light btn-sm circle-25" @click="editAddress('billingAddress')"
                                    :disabled="loading.deleteAddress == 'billing'">
                                    <i class="fa fa-pencil-alt text-info"></i>
                                </button>
                                <button class="btn btn-light btn-sm circle-25 ml-2"
                                    @click="fnApiDeleteAddress('billing')"
                                    :disabled="loading.deleteAddress == 'billing'">
                                    <i class="fa fa-spinner fa-spin fa-sm "
                                        v-if="loading.deleteAddress == 'billing'"></i>
                                    <i class="fa fa-trash-alt text-danger" v-else></i>
                                </button>
                            </div>
                        </div>
                        <div class="text-muted">
                            <p>
                                <small class="f-w-500">
                                    <span
                                        v-text=" newCart.billing_address.first_name + ' ' + newCart.billing_address.last_name + ' - ' "></span>
                                    <span>
                                        <i class="fa fa-phone mr-1"></i>
                                        <span v-text=" newCart.billing_address.phone "></span>
                                    </span>
                                </small>
                                <br>
                                <small>
                                    <span v-text="fnFormatAddress( newCart.billing_address )"></span>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card shadow-none" v-if="show.shippingAddress">
                    <div class="card-body">
                        <div class="row justify-content-between align-items-center mb-1">
                            <div class="col-8">
                                <p class="f-w-500 m-0 text-success">
                                    <i class="fa fa-map-marked-alt mr-2 "></i>
                                    <span v-text="$t('dashboard.order.shipping.shippingAddress')"></span>
                                </p>
                            </div>
                            <div class="col-auto d-flex">
                                <button class="btn btn-light btn-sm circle-25" @click="editAddress('shippingAddress')"
                                    :disabled="loading.deleteAddress == 'shipping'">
                                    <i class="fa fa-pencil-alt text-info"></i>
                                </button>
                                <button class="btn btn-light btn-sm circle-25 ml-2"
                                    @click="fnApiDeleteAddress('shipping')"
                                    :disabled="loading.deleteAddress == 'shipping'">
                                    <i class="fa fa-spinner fa-spin fa-sm "
                                        v-if="loading.deleteAddress == 'shipping'"></i>
                                    <i class="fa fa-trash-alt text-danger" v-else></i>
                                </button>
                            </div>
                        </div>
                        <div class="text-muted">
                            <p>
                                <small class="f-w-500">
                                    <span
                                        v-text=" newCart.shipping_address.first_name + ' ' + newCart.shipping_address.last_name + ' - ' "></span>
                                    <span class="text-muted">
                                        <i class="fa fa-phone mr-1"></i>
                                        <span class="" v-text=" newCart.shipping_address.phone "></span>
                                    </span>
                                </small>
                            </p>
                            <p>
                                <small>
                                    <span v-text="fnFormatAddress( newCart.shipping_address )"></span>
                                </small>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card shadow-none" v-if="show.shippingRates">
                    <div class="card-body p-0">
                        <div class="row justify-content-between align-items-center px-3 pt-3">
                            <div class="col-8">
                                <p class="f-w-500 m-0 text-info ">
                                    <i class="fa fa-pallet mr-2 "></i>
                                    <span v-text="$t('general.shipments')"></span>
                                </p>
                            </div>
                            <div class="col-auto d-flex">
                                <button class="btn btn-light btn-sm circle-25" @click="fnApiCreateRates"
                                    :disabled="loading.updatignRates">
                                    <i class="fa fa-pencil-alt text-info"></i>
                                </button>
                                <button class="btn btn-light btn-sm circle-25 ml-2" @click="fnApiDeleteRates(true)"
                                    :disabled="loading.updatignRates">
                                    <i class="fa fa-spinner fa-spin fa-sm " v-if="loading.updatignRates"></i>
                                    <i class="fa fa-trash-alt text-danger" v-else></i>
                                </button>
                            </div>
                        </div>
                        <table class="table ecart-table table-sm table-striped mb-0 ">
                            <tbody>
                                <tr v-for=" (store) in newCart.shipping_items" :key="store.id">
                                    <td>
                                        <div class="row px-2">
                                            <div class="col-12 text-muted">
                                                <div class="row">
                                                    <div class="col-10">
                                                        <p>
                                                            <span class="f-w-500" v-text="store.store_name"></span>
                                                            <br>
                                                            <span class="f-w-500"
                                                                v-text="$t('general.origin') + ': ' "></span>
                                                            <span v-text="fnFormatAddress( store.origin )"></span>
                                                        </p>
                                                    </div>
                                                    <div class="col-2">
                                                        <p class="text-right">
                                                            <i class=" fa fa-question-circle "
                                                                v-b-popover.hover.top=" $t('messages.quoteShipment') "></i>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="row text-muted" v-if="store.rateSelected">
                                                    <div class="col-12 col-xl-8">
                                                        <p>
                                                            <span class="text-capitalize"
                                                                v-text="store.rateSelected.name"></span>
                                                            <span
                                                                v-text="' ( '  + store.rateSelected.delivery.dateDifference + ' ' + $t('dates.' + store.rateSelected.delivery.timeUnit ) + ' )' "></span>
                                                        </p>
                                                    </div>
                                                    <div class="col-12 col-xl-4">
                                                        <p class="text-right">
                                                            <span
                                                                v-text="fnFormatPrice(store.rateSelected.amount, newCart.currency) "
                                                                v-if="store.rateSelected.amount > 0"></span>
                                                            <span class="f-w-600 text-success" v-else
                                                                v-text="$t('general.free')"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 col-xl-4">
                <div class="card shadow-none ">
                    <div class="card-body form-rounded-inputs">
                        <v-observer ref="formEmail" @submit.prevent="fnValidateEmail()">
                            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.buyerEmail') ">
                                <div class="form-group mb-0">
                                    <label for="" v-text="$t('general.buyerEmail')"></label>
                                    <input type="email" placeholder="email@email.com" id="buyerEmail"
                                        :class=" { 'is-invalid':errors.length > 0 } "
                                        class="form-control form-control-sm" v-model="newCart.email">
                                    <div class="invalid-feedback" v-if="errors.length > 0">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </v-validation>
                        </v-observer>
                    </div>
                </div>
                <div class="card shadow-none bg-light">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text="'Subtotal: '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.subtotal , newCart.currency )"></span>
                                </p>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text=" $t('general.shipping') + ': '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.shipping , newCart.currency )"></span>
                                </p>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text="$t('general.discount') + ': '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.discount , newCart.currency )"></span>
                                </p>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text=" $t('general.form.tax') + ': '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.tax , newCart.currency )"></span>
                                </p>
                            </div>

                            <div class="col-12">
                                <hr>
                            </div>

                            <div class="col-6">
                                <p>
                                    <span class="f-w-500" v-text="'Total: '"></span>
                                </p>
                            </div>
                            <div class="col-6">
                                <p class="text-right">
                                    <span v-text="fnFormatPrice( newCart.totals.total , newCart.currency )"></span>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal v-model="showProductModal" size="xl" :title=" $t('general.form.invoiceInformation') "
            content-class="card form-rounded-inputs card-ecart" header-class="card-header" ok-disabled
            cancel-variant="none" centered>
            <div class="card shadow-none border-0">
                <div class="card-header">
                    <v-observer tag="form" @submit.prevent="fnResetPagination()">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group m-0">
                                    <label v-text="$t('tables.product')"> </label>
                                    <div class="input-group ">
                                        <input type="text" class="form-control" :placeholder="$t('tables.name')"
                                            v-model="pagination.name " @change="fnResetPagination()">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group m-0 ">
                                    <label for="" v-text="$t('dashboard.products.store')"></label>
                                    <div class="input-group">
                                        <select class="custom-select" v-model="pagination.storeId "
                                            @change="fnResetPagination() ">
                                            <option :value="null" v-text="$t('general.form.all')"> </option>
                                            <option :value="store.search_store_id" v-for=" (store,index) in storeList "
                                                :key="index"
                                                v-text=" store.name || fnStoreURL(store.url) || store.store_id  ">
                                            </option>
                                        </select>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary">
                                                <i class="fa fa-store"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-observer>
                    <p class="text-right m-0">
                        <small v-text=" $t('general.form.coincidences') + ': ' + coincidences "></small>
                    </p>
                </div>
                <div class="card-body p-0">
                    <div class="produsct-list">
                        <table class="table table-sm table-striped ecart-table "
                            v-if="!loading.products && (productsList.length > 0) ">
                            <tbody>
                                <template v-for=" (product,index) in productsList ">
                                    <tr :key="product.id">
                                        <td class=" text-center ">
                                            <img v-image="product.imageUrl" class="img-contain-40 border bg-white">
                                        </td>
                                        <td class="w-100">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-sm-8">
                                                    <div class="row align-items-center">
                                                        <div class="col-10 col-md ">
                                                            <p class="mb-0">
                                                                <span
                                                                    v-text=" product.name ? product.name : product.id "></span>
                                                            </p>
                                                            <p class="mb-0">
                                                                <strong class="f-w-600"
                                                                    v-text=" $t('general.form.price') + ': ' "></strong>
                                                                <span class="text-success f-w-500"
                                                                    v-text="fnFormatPrice(product.price , product.currency )"></span>
                                                                <small class="text-warning f-w-500 ml-1"
                                                                    v-if="product.discountDef"
                                                                    v-text="(product.discountDef.is_amount ? fnFormatPrice(product.discountDef.percent, product.currency) : (product.discountDef.percent + '%')) + ' OFF'  "></small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-9 col-sm-2 ml-auto" v-show="!product.variants">
                                                    <div class="form-group mb-0">
                                                        <input type="number" min="1" step="1"
                                                            class="form-control-sm form-control w-100"
                                                            v-model="product.quantitySelected" v-if="!product.variants">
                                                    </div>
                                                </div>
                                                <div class="col-3 col-sm-auto" v-show="!product.variants">
                                                    <div class="form-group mb-0">
                                                        <button class="btn btn-success circle-30 ml-auto"
                                                            :disabled="product.loading.adding"
                                                            @click="fnApiAddProduct(index,product.quantitySelected,null)">
                                                            <i class="fa fa-spinner fa-spin fa-sm "
                                                                v-if="product.loading.adding"></i>
                                                            <i class="fa fa-plus fa-sm" v-else></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-3 ml-auto" v-show="product.variants">
                                                    <div class="form-group mb-0">
                                                        <button @click="fnApiGetVariants(index)"
                                                            :disabled="product.loading.variants "
                                                            class="btn btn-success circle-30 ml-auto">
                                                            <div v-if="product.showVariants">
                                                                <i class="fa fa-caret-up"></i>
                                                            </div>
                                                            <div v-else>
                                                                <i class="fa fa-spinner fa-spin fa-sm "
                                                                    v-if="product.loading.variants"></i>
                                                                <i class="fa fa-caret-down" v-else></i>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                    <div class="table-tr border-bottom border-1 f-12" :key=" product.id + index "
                                        v-show="product.variantsList && (product.variantsList.length > 0) && (product.showVariants) ">
                                        <td></td>
                                        <td colspan="2" class="text-success">
                                            <strong v-text="$t('tables.variants')"></strong>
                                        </td>
                                    </div>

                                    <div class="table-tr animated fadeIn"
                                        v-for=" (variant,indexVar) in product.variantsList "
                                        :key=" variant.id +  indexVar "
                                        v-show=" product.variantsList && (product.variantsList.length > 0) && (product.showVariants)  "
                                        :class="  { 'border-bottom border-2 ': (indexVar == (product.variantsList.length - 1 )) , ' bg-light  ': (indexVar%2 != 0 ) } ">
                                        <td>
                                        </td>
                                        <td colspan="2" class="w-100">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-sm-8">
                                                    <label :for="variant.id + indexVar" class="mb-0">
                                                        <span class="f-w-600"
                                                            v-text=" variant.name ? variant.name : product.id "></span>
                                                        <br>
                                                        <strong class="f-w-500"
                                                            v-text=" $t('general.form.price') + ': ' "></strong>
                                                        <span class="text-success f-w-400"
                                                            v-text="fnFormatPrice(variant.price , variant.currency )"></span>
                                                        <small class="text-warning f-w-400 ml-1"
                                                            v-if="variant.discountDef"
                                                            v-text="(variant.discountDef.is_amount ? fnFormatPrice(variant.discountDef.percent, variant.currency) : (variant.discountDef.percent + '%')) + ' OFF'  "></small>
                                                    </label>
                                                </div>
                                                <div class="col-9 col-sm-2 ml-auto">
                                                    <div class="form-group mb-0">
                                                        <input type="number" class="form-control form-control-sm"
                                                            v-model="variant.quantity">
                                                    </div>
                                                </div>
                                                <div class="col-3 col-sm-auto">
                                                    <div class="form-group mb-0">
                                                        <button class="btn btn-outline-success circle-30 ml-auto"
                                                            :disabled="product.loading.adding"
                                                            @click="fnApiAddProduct(index,variant.quantity, indexVar)">
                                                            <i class="fa fa-spinner fa-spin fa-sm "
                                                                v-if="product.loading.adding"></i>
                                                            <i class="fa fa-plus fa-sm" v-else></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </div>

                                </template>
                            </tbody>

                        </table>
                        <div class="d-flex align-items-center justify-content-center h-100" v-else>
                            <no-data :dataMessage="$t('noDataMessages.noInformaton')" :showBtn="false"
                                :isLoading="loading.products" sm>
                            </no-data>
                        </div>
                    </div>
                </div>
            </div>

            <template v-slot:modal-footer>
                <div class="d-flex w-100">
                    <button @click=" showProductModal = false" class="btn ml-auto"
                        v-text=" $t('general.button.close') "></button>
                </div>
            </template>

        </b-modal>
    </div>
</template>

<script>
    import {
        mapState,
        mapActions,
        mapMutations,
    } from 'vuex';

    export default {
        props: {
            preselectedProducts: {
                type: Array,
                required: false,
            },
        },
        computed: {
            ...mapState('EcartNewCart', ['storeId', 'storeList', 'newCart', 'show', 'loading']),
        },
        data() {
            return {
                productsSelectedList: [],
                productsList: [],
                // loading: {
                //     products: true,
                //     product: false,
                //     addProduct: null,
                //     productVariants: null,
                // },
                pagination: {
                    storeId: null,
                    name: null,
                    active: null,
                    page: 1,
                    limit: 50,
                    total: null,
                    improvements: null,
                },
                coincidences: 0,
                showProductModal: false,
            }
        },
        watch: {
            storeId() {
                if (this.storeId) {
                    this.fnFirstPagination();
                }
            },
        },
        methods: {
            ...mapMutations('EcartNewCart', ['setCart', 'deleteItem', 'editAddress']),
            ...mapActions('EcartNewCart', ['fnApiCreateRates', 'fnApiUpdateItem', 'fnApiDeleteItem',
                'fnApiDeleteRates', 'fnApiDeleteAddress', 'fnApiUpdateEmail'
            ]),

            async fnValidateEmail(create) {
                if (await this.$refs.formEmail.validate()) {
                    await this.fnApiUpdateEmail(create);
                    return true;
                } else {
                    this.fnGlobalScrollToError(this.$refs.formEmail.refs, true);
                    return false;
                }
            },

            async fnApiGetVariants(index) {
                let product = this.productsList[index];
                if (product.showVariants) {
                    product.showVariants = false;
                    return null;
                }
                if (product.variantsList.length == 0) {
                    product.loading.variants = true;
                    await axios.get(`products/${product.id}/variants`).then((response) => {
                        response.data.map(variant => {
                            variant.quantity = 1;
                        });
                        product.variantsList = response.data;
                        if (product.discountDef && product.discountDef.variant_ids) {
                            product.discountDef.variant_ids.map(variantId => {
                                let varIndex = product.variantsList.findIndex(variant => variant
                                    .id ==
                                    variantId);
                                if (varIndex >= 0) {
                                    product.variantsList[varIndex].discountDef = product
                                        .discountDef;
                                }
                            });
                        }
                    }).catch(error => false);
                    product.loading.variants = false;
                }
                product.showVariants = true;
            },

            async fnApiAddProduct(index, quantity, varIndex) {
                let productData = this.productsList[index];
                let tempProduct = {
                    item_id: productData.id,
                    quantity,
                }
                if (varIndex !== null) {
                    tempProduct.variant_id = productData.variantsList[varIndex].id;
                }
                let tempItemIndex = this.newCart.items.findIndex(item => tempProduct.variant_id ? item.variant_id ==
                    tempProduct
                    .variant_id : item.item_id == productData.id);
                productData.loading.adding = true;
                if (tempItemIndex >= 0) {
                    this.newCart.items[tempItemIndex].quantity = Number(this.newCart.items[tempItemIndex]
                        .quantity) + Number(quantity);
                    await this.fnApiUpdateItem(tempItemIndex);
                } else {
                    await axios.post(`carts/${this.newCart.id}/items`, tempProduct).then(async (response) => {
                        this.setCart(response.data);
                        this.$toasted.global.infoMessage(this.$t('toasted.info.productAdded'));
                    }).catch(error => {});
                }
                productData.loading.adding = false;
            },
            fnResetData() {
                this.productsSelectedList = [];
                this.fnFirstPagination();
            },
            fnFirstPagination() {
                this.pagination.page = 1;
                this.pagination.limit = this.CONST.PAGINATION[0];
                this.pagination.storeId = this.storeId || null;
                this.pagination.name = null;
                this.fnApiGetProducts();
                this.fnApiGetCount();
            },
            async fnResetPagination() {
                this.pagination.page = 1;
                this.fnApiGetProducts();
                this.fnApiGetCount();
            },

            async fnApiGetProducts() {
                this.loading.products = true;
                let {
                    name,
                    storeId,
                    active,
                    limit,
                    improvements,
                    page
                } = this.$lodash.clone(this.pagination);
                let tempPagination = {
                    page,
                    limit
                };
                if (name != null && name != '') {
                    tempPagination.name = name
                };
                if (storeId != null && storeId != '') {
                    tempPagination.store_id = storeId
                };
                if (active !== null && active !== '') {
                    tempPagination.active = active
                };
                if (improvements !== null && improvements !== '') {
                    tempPagination.improvements = improvements
                };
                await axios.get('products', {
                    params: tempPagination
                }).then(async (response) => {
                    if (response.data.length > 0) {
                        this.productsList = response.data.map((product) => {
                            product.selected = false;
                            product.showVariants = false;
                            product.discountDef = null;
                            product.quantitySelected = 1;
                            product.variantsList = [];
                            product.loading = {
                                variants: false,
                                adding: false,
                                deleting: false,
                            };
                            product.discountDef = product.discounts.find(disc => disc.active);
                            return product;
                        });
                    }
                }).catch(error => {});
                this.loading.products = false;
            },

            fnApiGetCount() {
                let {
                    name,
                    storeId,
                    improvements,
                    active
                } = this.$lodash.cloneDeep(this.pagination);
                let tempPagination = {};
                if (name != null && name != '') {
                    tempPagination.name = name
                };
                if (storeId != null && storeId != '') {
                    tempPagination.store_id = storeId
                };
                if (active !== null && active !== '') {
                    tempPagination.active = active
                };
                if (improvements !== null && improvements !== '') {
                    tempPagination.improvements = improvements
                };

                axios.get('products/count', {
                    params: tempPagination
                }).then(async (response) => {
                    this.pagination.total = response.data.count;
                }).catch(error => {});
            },
        },
        mounted() {
            this.fnFirstPagination();
        },
    }
</script>

<style scoped>
    .produsct-list-order {
        min-height: 250px;
        height: 380px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .produsct-list {
        height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>